<template>
  <section class="bg-SnowWhite min-h-screen">
    <div class="container px-6 pt-24 pb-20">
      <h2
        class="max-w-[923px] mx-auto text-4xl md:text-5xl text-center title-gradient tracking-[-1.44px]"
      >
        We can help you navigate platform reviews, overcome restricted
        advertising challenges, and manage expenditure limits.
      </h2>

      <div class="mt-24 md:mt-[120px] mb-14 lg:mb-28 flex justify-center gap-2">
        <button
          class="bg-ResolutionBlue w-[14px] h-[14px] rounded-full"
        ></button>
        <button
          class="bg-ResolutionBlue/10 w-[14px] h-[14px] rounded-full"
        ></button>
        <button></button>
      </div>

      <div class="relative">
        <img
          src="../../assets/images/expenditure-limit.png"
          alt="woman raisinh her hands in excitement"
          class="mx-auto"
        />
        <img
          src="../../assets/images/play.png"
          alt="play"
          class="w-12 h-12 md:w-[initial] md:h-[initial] absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
    </div>
  </section>
</template>
