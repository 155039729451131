<template>
  <section class="bg-SnowWhite min-h-screen">
    <div class="container px-6 py-24 font-general-sans-medium">
      <div class="text-center mb-20">
        <h2
          class="max-w-[628px] mx-auto title-gradient text-5xl md:text-6xl mb-7"
        >
          Choose Your Monthly Subscription Plan Today
        </h2>
        <p class="font-general-sans text-xl md:text-2xl">
          We make sure that your ads are compliant and running.
        </p>
      </div>

      <div class="flex flex-col xl:flex-row justify-between gap-8">
        <div
          class="bg-BlackRussian bg-monthly-plan-background bg-no-repeat bg-center bg-cover p-9 md:p-12 rounded-[25px] text-white"
        >
          <div class="flex items-center gap-[18px] mb-10">
            <h3 class="text-3xl md:text-[32px]">Elevate</h3>
            <p
              class="bg-TitanWhite text-BlackRussian text-xs py-1 px-[10px] rounded-[2px]"
            >
              POPULAR
            </p>
          </div>

          <div>
            <p class="text-lg md:text-xl mb-3">Our Growth package includes:</p>
            <ul class="text-sm md:text-base flex flex-col gap-3 mb-9">
              <li class="flex items-center gap-4">
                <div>
                  <img
                    src="../../../assets/svgs/checkmark-2.svg"
                    alt="checkmark icon"
                  />
                </div>
                <p>Ad Compliance Checks</p>
              </li>

              <li class="flex items-center gap-4">
                <div>
                  <img
                    src="../../../assets/svgs/checkmark-2.svg"
                    alt="checkmark icon"
                  />
                </div>
                <p>General Compliance Report</p>
              </li>

              <li class="flex items-center gap-4">
                <div>
                  <img
                    src="../../../assets/svgs/checkmark-2.svg"
                    alt="checkmark icon"
                  />
                </div>
                <p>Customer Support</p>
              </li>

              <li class="flex items-center gap-4">
                <div>
                  <img
                    src="../../../assets/svgs/checkmark-2.svg"
                    alt="checkmark icon"
                  />
                </div>
                <p>Up to 5 Free Agency Ad Account</p>
              </li>

              <li class="flex items-center gap-4">
                <div>
                  <img
                    src="../../../assets/svgs/checkmark-2.svg"
                    alt="checkmark icon"
                  />
                </div>
                <p>Shared Customer Success Manager</p>
              </li>
            </ul>
          </div>

          <div>
            <router-link
              to="#"
              class="inline-block font-general-sans-medium bg-white py-4 px-8 text-black text-sm md:text-base rounded-lg"
            >
              Contact Us
            </router-link>
          </div>
        </div>

        <div
          class="bg-BlueChalk border border-Indigo rounded-[25px] p-9 md:p-12 text-BlackRussian"
        >
          <h3 class="card-title-linear-gd text-3xl md:text-[32px] mb-9">
            Hedging
          </h3>

          <div>
            <p class="text-lg md:text-xl mb-3">
              Our Arbitrage package includes:
            </p>
            <div class="flex flex-wrap gap-y-4 gap-x-[60px] mb-[18px]">
              <ul class="text-sm md:text-base flex flex-col gap-4">
                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>Ad Compliance Checks</p>
                </li>

                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>Detailed Compliance Report</p>
                </li>

                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>Complete Asset Audits</p>
                </li>

                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>Strategy</p>
                </li>

                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>Instant Support</p>
                </li>
              </ul>

              <ul class="text-sm md:text-base flex flex-col gap-4">
                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>20 Free Agency Ad Accounts</p>
                </li>

                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>Dedicated Customer Success Manager</p>
                </li>

                <li class="flex items-center gap-4">
                  <div>
                    <img
                      src="../../../assets/svgs/checkmark.svg"
                      alt="checkmark icon"
                    />
                  </div>
                  <p>Webinars & Insights</p>
                </li>
              </ul>
            </div>

            <div>
              <router-link
                to="#"
                class="inline-block font-general-sans-medium bg-BlackRussian py-4 px-8 text-white text-sm md:text-base rounded-lg"
              >
                Contact Us
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.card-title-linear-gd {
  background: linear-gradient(102deg, #850000 -18.69%, #010022 150.86%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
