<template>
  <section class="bg-SnowWhite">
    <div
      class="container px-6 py-24 flex flex-col lg:flex-row gap-14 md:gap-44"
    >
      <h2
        class="block md:hidden text-5xl md:text-6xl text-center lg:text-left title-gradient"
      >
        Our Services
      </h2>
      <div>
        <img
          src="../../assets/images/our-service-img.png"
          alt="lady with a laptop"
          class="w-full"
        />
      </div>

      <div>
        <h2
          class="hidden md:block text-5xl md:text-6xl text-center lg:text-left title-gradient mb-8"
        >
          Our Services
        </h2>
        <h5
          class="font-general-sans-medium text-lg md:text-xl text-center lg:text-left mb-2"
        >
          Get around-the-clock assistance
        </h5>
        <div
          class="max-w-[512px] mx-auto text-lg md:text-xl text-center lg:text-left flex flex-col gap-5"
        >
          <p>
            With our 24-hour support service, you can navigate any advertising
            issues.
          </p>
          <p>
            Our team is here to help you at every stage! Any question about your
            advertising efforts can be answered here.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
