<template>
  <section class="bg-about-us-hero-background bg-no-repeat bg-center bg-cover">
    <div
      class="container px-6 pt-44 pb-[60px] flex justify-between items-center gap-11 lg:gap-[54px]"
    >
      <div
        class="w-full lg:w-7/12 text-white text-center lg:text-left space-y-8 md:space-y-12"
      >
        <h3
          class="text-3xl md:text-[64px] md:leading-[4.5rem] font-general-sans-medium"
        >
          Step into advertising without limits!
        </h3>
        <div class="space-y-4">
          <p
            class="font-general-sans text-xl md:text-[20px] md:leading-[2.2rem] md:max-w-[32rem]"
          >
            Our mission is to help our clients create an uninterrupted
            advertising ecosystem. Our team of expert advertisers know how to
            scale your campaigns without interruptions. Whether from platform
            reviews, restricted advertising or limits of spend we are on top of
            it all. Our knowledge of compliance, experience in direct to market
            advertising and access to platform representatives gives an edge for
            all our customers.
          </p>
        </div>
      </div>

      <div class="hidden lg:block w-5/12">
        <img
          src="../../assets/images/about-us-hero-img.png"
          alt="Iphone"
          class=""
        />
      </div>
    </div>
  </section>
</template>
