import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import AboutView from "../views/AboutView.vue";
import ContactUsView from "../views/ContactUsView.vue";
import ServicesView from "../views/ServicesView.vue";
import MetaAdvertisingPackagesView from "@/views/services/MetaAdvertisingPackagesView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Explore the vast world of advertising options!",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/about-us",
    name: "about",
    component: AboutView,
    meta: {
      title: "About Us | Elevate Impact",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/contact-us",
    name: "contact",
    component: ContactUsView,
    meta: {
      title: "Contact Us | Elevate Impact",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/metapeak-perfomance-pack",
    name: "services",
    component: ServicesView,
    meta: {
      title: "Metapeak Perfomance Pack | Elevate Impact",
      toTop: true,
      smoothScroll: true,
    },
  },
  {
    path: "/meta-advertising-packages",
    name: "services-meta-advertising-packages",
    component: MetaAdvertisingPackagesView,
    meta: {
      title: "Meta Advertising Packages | Elevate Impact",
      toTop: true,
      smoothScroll: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    const scroll = {};
    if (to.meta.toTop) scroll.top = 0;
    if (to.meta.smoothScroll) scroll.behavior = "smooth";
    return scroll;
  },
});

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  next();
});

export default router;
