<template>
  <main>
    <Header />
    <Hero @scrollToGetStarted="scrollToGetStarted" />
    <ExpenditureLimits />
    <WhyElevateImpact />
    <OurServices />
    <Benefits />
    <ContactUsBody ref="componentB" />
    <!-- <ContactUs /> -->
  </main>
</template>

<script setup>
import { ref } from "vue";
import Header from "@/components/Header.vue";
// import Hero from "@/components/Hero.vue";
// import ExpenditureLimits from "@/components/ExpenditureLimits.vue";
// import WhyElevateImpact from "@/components/WhyElevateImpact.vue";
// import OurServices from "@/components/OurServices.vue";
import Hero from "@/components/homepage/Hero.vue";
import ExpenditureLimits from "@/components/homepage/ExpenditureLimits.vue";
import WhyElevateImpact from "@/components/homepage/WhyElevateImpact.vue";
import OurServices from "@/components/homepage/OurServices.vue";
import Benefits from "@/components/homepage/Benefits.vue";
// import ContactUs from "@/components/homepage/ContactUs.vue";
import ContactUsBody from "@/components/contactSection/ContactUsBody.vue";

const componentB = ref(null);

const scrollToGetStarted = () => {
  if (componentB.value) {
    componentB.value.$el.scrollIntoView({ behavior: "smooth" });
  }
};
</script>
