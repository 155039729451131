<template>
  <section class="bg-white min-h-screen">
    <div class="container px-6 py-24">
      <div class="text-center mb-16">
        <h2 class="title-gradient text-5xl md:text-6xl mb-7">
          Essential Package
        </h2>
        <p class="text-xl md:text-2xl">
          A simple solution to all your advertising problems
        </p>
      </div>

      <div
        class="pt-12 flex flex-col lg:flex-row justify-center items-center gap-20 lg:gap-[106px]"
      >
        <div class="order-2 lg:order-1">
          <div>
            <p class="lg:max-w-[385px] mb-6 text-center lg:text-left">
              Our Essential Package comes fully equipped to deal with all your
              Meta headaches! Access a complete set of tools to level up your
              social media marketing campaigns.
            </p>
          </div>

          <ul
            class="font-general-sans-medium text-DarkGrey text-sm md:text-base flex flex-col gap-4 mb-9"
          >
            <li class="flex items-center gap-3">
              <div>
                <img
                  src="../../../assets/svgs/checkmark.svg"
                  alt="checkmark icon"
                />
              </div>
              <p>Ad Compliance Checks</p>
            </li>

            <li class="flex items-center gap-3">
              <div>
                <img
                  src="../../../assets/svgs/checkmark.svg"
                  alt="checkmark icon"
                />
              </div>
              <p>General Compliance Report</p>
            </li>

            <li class="flex items-center gap-3">
              <div>
                <img
                  src="../../../assets/svgs/checkmark.svg"
                  alt="checkmark icon"
                />
              </div>
              <p>Customer Support</p>
            </li>

            <li class="flex items-center gap-3">
              <div>
                <img
                  src="../../../assets/svgs/checkmark.svg"
                  alt="checkmark icon"
                />
              </div>
              <p>Free Agency Ad Account</p>
            </li>

            <li class="flex items-center gap-3">
              <div>
                <img
                  src="../../../assets/svgs/checkmark.svg"
                  alt="checkmark icon"
                />
              </div>
              <p>Shared Customer Success Manager</p>
            </li>
          </ul>

          <div class="max-w-[435px] font-general-sans-medium mb-6">
            <p class="text-black text-sm mb-3">
              Run successful Meta ad campaigns with our Essential Package,
              increase conversion rates, and boost your numbers!
            </p>
            <p class="font-general-sans text-SmokeyGrey text-xs">
              *No minimum monthly spending required. Additional service fee of
              10% on Media spend applies. Terms and Conditions apply.
            </p>
          </div>

          <div>
            <router-link
              to="#"
              class="inline-block font-general-sans-medium bg-BlackRussian py-4 px-8 text-white text-sm md:text-base rounded-lg"
            >
              Contact Us
            </router-link>
          </div>
        </div>

        <div class="order-1 lg:order-2">
          <img
            src="../../../assets/images/premium-service.png"
            alt="premium service"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup></script>

<style scoped></style>
