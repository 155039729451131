<template>
  <section class="px-2 md:px-0 mb-8 md:mb-16">
    <div
      class="container mx-auto px-4 md:px-24 py-24 flex flex-col items-center lg:flex-row justify-between gap-14 lg:gap-44"
    >
      <div class="w-full lg:w-[45%] order-2 lg:order-1">
        <img
          src="../../assets/images/who-are-we-about-us.png"
          alt="lady with a laptop"
          class="w-full"
        />
      </div>
      <div class="order-1 lg:order-2 w-full lg:w-[55%]">
        <h2
          class="font-general-sans-medium md:text-[40px] md:leading-[2.5rem] text-center lg:text-left mb-8 md:mb-12"
        >
          Who Are We?
        </h2>
        <div
          class="text-lg md:text-xl text-center lg:text-left flex flex-col gap-5"
        >
          <p class="md:max-w-[32rem]">
            Elevate Impact is your go-to performance advertising consultancy,
            committed to assisting companies in overcoming scaling challenges on
            digital ad platforms.
          </p>
          <p class="md:max-w-[30rem]">
            We specialize in services such as whitelisting, reducing ad spend
            limits, assuring content compliance, and securing content
            pre-approval.
          </p>
          <p class="md:max-w-[30rem]">
            Direct communication with ad platform professionals and years of
            hands-on experience in the business fuel our expertise in
            negotiating the complexity of social media.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
