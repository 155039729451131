<template>
  <header class="absolute w-full left-0 top-0 z-40">
    <div
      class="container px-6 py-6 md:py-9 flex justify-between lg:justify-normal items-center"
    >
      <router-link to="/">
        <img :src="getImagePath" class="w-32 md:w-fit" alt="Elevate logo" />
      </router-link>

      <nav
        class="hidden font-general-sans-medium bg-GreenWhite text-black text-sm md:text-base px-10 lg:px-[60px] md:flex justify-between items-center gap-14 md:gap-20 lg:gap-28 rounded-[20px] mx-auto"
      >
        <div class="relative cursor-pointer">
          <p class="py-5" @mouseenter="showSubNav = true">Services</p>
          <Transition>
            <div
              v-show="showSubNav"
              class="absolute left-0 top-full mt-1 bg-GreenWhite w-[280px] rounded-xl p-5 font-general-sans text-DarkJungleGreen flex flex-col gap-1 transition-all duration-300 shadow-md"
              @mouseleave="showSubNav = false"
            >
              <router-link to="/meta-advertising-packages"
                >Meta Advertising Packages</router-link
              >
              <router-link to="/metapeak-perfomance-pack"
                >MetaPeak Performance Pack</router-link
              >
            </div>
          </Transition>
        </div>
        <router-link to="/contact-us" class="py-5">Contact</router-link>
        <router-link to="/about-us" class="py-5">About</router-link>
      </nav>

      <div
        class="md:hidden border border-white p-2 rounded"
        @click="showSidebar = true"
      >
        <span
          class="inline-block w-6 h-[2px] bg-white relative -top-1 before:absolute before:w-full before:h-full before:bg-white before:left-0 before:-top-2 after:absolute after:w-full after:h-full after:bg-white after:left-0 after:-bottom-2"
        ></span>
      </div>
    </div>
  </header>
</template>

<script setup>
import { inject, ref, computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const showSidebar = inject("showSidebar");
const showSubNav = ref(false);
router.afterEach(() => (showSubNav.value = false));

const white_logo = require("../assets/svgs/ElevateImpact-logo.svg");
const black_logo = require("../assets/logos/elevate-impact-black-logo.svg");

const getImagePath = computed(() => {
  const currentRoute = router.currentRoute.value.path;

  if (currentRoute === "/contact-us") {
    return black_logo;
  } else {
    return white_logo;
  }
});
</script>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
