<template>
  <aside
    class="md:hidden fixed top-0 left-0 w-full h-full bg-black text-white transition-all duration-300 z-50"
    :class="showSidebar ? 'translate-x-0' : '-translate-x-full'"
  >
    <div class="p-6">
      <div class="mb-10 flex justify-between items-center">
        <div class="w-[180px]">
          <router-link to="/" @click="closeSidebar">
            <img
              src="../assets/svgs/ElevateImpact-logo.svg"
              alt="Elevate logo"
            />
          </router-link>
        </div>

        <div @click="showSidebar = false">
          <span
            class="inline-block w-5 h-[2px] bg-transparent relative -top-1 before:absolute before:w-full before:h-full before:bg-white before:left-0 before:top-0 before:-rotate-45 after:absolute after:w-full after:h-full after:bg-white after:left-0 after:top-0 after:rotate-45"
          ></span>
        </div>
      </div>

      <div
        class="font-general-sans-medium text-white text-base flex flex-col gap-3"
      >
        <div>
          <p
            class="pb-1 border-white border-b cursor-pointer"
            :class="toggleServicesRoutes && 'mb-3'"
            @click="toggleServicesRoutes = !toggleServicesRoutes"
          >
            Services
          </p>
          <div
            class="flex flex-col gap-2 overflow-hidden transition-all duration-300 text-sm"
            :class="toggleServicesRoutes ? 'h-16' : 'h-0'"
          >
            <router-link to="/meta-advertising-packages" @click="closeSidebar"
              >Meta Advertising Packages</router-link
            >
            <router-link to="/metapeak-perfomance-pack" @click="closeSidebar"
              >MetaPeak Performance Pack</router-link
            >
          </div>
        </div>

        <router-link to="/contact-us" @click="closeSidebar"
          >Contact</router-link
        >
        <router-link to="/about-us" @click="closeSidebar">About</router-link>
      </div>
    </div>
  </aside>
</template>

<script setup>
import { inject, ref, watchEffect } from "vue";
import { useRoute } from "vue-router";

const showSidebar = inject("showSidebar");
const route = useRoute();
const toggleServicesRoutes = ref(false);

const closeSidebar = () => {
  showSidebar.value = false;
};

// Close the sidebar when the route changes
watchEffect(() => {
  showSidebar.value = false;
});
</script>
