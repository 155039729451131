<template>
  <footer class="bg-Dark text-GreenWhite">
    <div class="container px-6 pt-24 pb-6">
      <div class="mb-10">
        <img src="../assets/images/Elevate-logo.png" alt="Elevate logo" />
      </div>

      <div class="flex flex-wrap gap-y-10 gap-x-[165px] mb-[140px]">
        <div class="flex flex-col gap-2 text-xs md:text-[14px] font-medium">
          <p>1, SILAS ILO ROAD, GUZAPE, ABUJA, FCT, NIGERIA</p>
          <p>+234 818 887 2426</p>
          <p>
            <a class="underline" href="mailto:hello@elevateimpact.ng"
              >hello@elevateimpact.ng</a
            >
          </p>
        </div>

        <div class="flex gap-16 text-sm md:text-base">
          <div class="flex flex-col gap-3">
            <router-link
              class="hover:underline transition-all duration-500"
              to="/meta-advertising-packages"
              >Meta Advertising Packages</router-link
            >
            <router-link
              class="hover:underline transition-all duration-500"
              to="/metapeak-perfomance-pack"
              >MetaPeak Performance Pack</router-link
            >
          </div>

          <div class="flex flex-col gap-3">
            <router-link
              class="hover:underline transition-all duration-500"
              to="/contact-us"
              >Contact</router-link
            >
            <router-link
              class="hover:underline transition-all duration-500"
              to="/about-us"
              >About</router-link
            >
          </div>
        </div>
      </div>

      <small class="font-inter text-[10px]">© 2023 Elevate Impact</small>
    </div>
  </footer>
</template>
