<template>
  <section
    class="min-h-screen bg-meta-advertising-packages-background bg-no-repeat bg-center bg-cover"
  >
    <div
      class="container px-6 pt-[151px] pb-20 flex justify-between items-center gap-11 lg:gap-[54px]"
    >
      <div
        class="w-full lg:w-1/2 font-general-sans-medium text-white text-center lg:text-left"
      >
        <h1 class="text-7xl md:text-8xl mb-9 tracking-[-2.88px] leading-[99%]">
          <span class="font-general-sans">Your Partner for</span>
          Ad Compliance
        </h1>
        <p class="font-general-sans lg:max-w-[475px] text-xl md:text-2xl mb-8">
          Empower your advertising success through seamless scaling and
          compliance expertise.
        </p>

        <div
          class="flex justify-center lg:justify-normal items-center flex-wrap gap-4 mb-14"
        >
          <img src="../../../assets/svgs/meta.svg" alt="Meta" />
          <p class="font-general-sans lg:max-w-[202px] text-sm md:text-base">
            We make sure that your ads are compliant and running.
          </p>
        </div>
      </div>

      <div class="hidden lg:block w-1/2">
        <img
          src="../../../assets/images/meta-advertising-packages-hero.png"
          alt="a lady and a guy throwing a thumbs up"
          class="ml-auto"
        />
      </div>
    </div>
  </section>
</template>
