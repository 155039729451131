<template>
  <div class="container mx-auto">
    <h2
      class="font-general-sans-medium md:text-[45px] md:leading-[3rem] lg:text-[54px] lg:leading-[4rem] text-center lg:text-center title-gradient mb-8"
    >
      Advertising Access Permanently Restricted? Introducing the Ultimate Meta
      Pack
    </h2>

    <div
      class="flex flex-col items-start space-y-4 md:space-y-0 md:flex-row md:space-x-16 py-6 md:py-12"
    >
      <div class="w-full md:w-1/2 lg:pt-24">
        <div
          class=""
          v-for="(advertisingAccess, index) in advertisingAccessArray"
          :key="index"
        >
          <div class="flex items-center space-x-3 md:space-x-6 mb-4 md:mb-8">
            <img :src="advertisingAccess.icon" class="scale-110" alt="" />
            <p class="font-general-sans-medium text-lg md:text-2xl">
              {{ advertisingAccess.title }}
            </p>
          </div>
        </div>
      </div>
      <div class="w-full md:w-1/2">
        <div
          class="rounded-3xl sca px-16 py-20 border-[0.1px] border-Dawn shadow-2xl"
        >
          <h3 class="font-general-sans-medium text-3xl md:text-4xl mb-10">
            Ultimate Meta Pack
          </h3>
          <div
            class=""
            v-for="(advertisingAccess, index) in advertisingAccess_second_Array"
            :key="index"
          >
            <div
              class="flex items-center space-x-3 md:space-x-6 mb-0.5 md:mb-3"
            >
              <img :src="advertisingAccess.icon" class="scale-90" alt="" />
              <p class="font-general-sans-medium text-base md:text-lg">
                {{ advertisingAccess.title }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const advertisingAccessArray = ref([
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: " Avoid Facebook Ads restrictions",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Bypassing any issues with Facebook Business Manager restrictions",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Securing Access",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Providing maximum results to our clients",
  },
]);

const advertisingAccess_second_Array = ref([
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Ad Policy Compliance",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Ad Reports & Audits",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Up to 5 Agency Ad Account",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Unlimted Ad Spend",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Customer Success Manager & Support",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "2 Authentic FB Profiles",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Centralized Access Proxy Software",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Unlimited BM & FB Profile Replacements",
  },
  {
    icon: require("../../assets/svgs/checked-icon.svg"),
    title: "Entirely Plug and Play solution",
  },
]);
</script>
