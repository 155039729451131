<template>
  <div class="px-4 md:px-0">
    <section class="container mx-auto rounded-2xl bg-Haiti text-white mb-24">
      <div class="container px-3 md:px-6 py-16 md:py-32">
        <div class="flex justify-center text-center">
          <h2
            class="flex flex-col linear-gd-titles text-xl sm:text-4xl md:text-5xl font-general-sans-medium mb-9 md:mb-20"
          >
            <span>Dive into the Five Main Culprits</span>
            <span>Behind Facebook Account Restrictions:</span>
          </h2>
        </div>

        <FiveMainCulpriteAccordion
          v-for="(faq, index) in faqs"
          :key="index"
          :culprite="faq.culprite"
          :culprite_description="faq.culprite_description"
          :idx="index"
          :length="faqs.length"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
import FiveMainCulpriteAccordion from "./FiveMainCulpriteAccordion.vue";

const faqs = [
  {
    culprite: "1. IP Address Quandaries",
    culprite_description:
      "Juggling different IP addresses in quick succession can trigger restrictions. The remedy lies in consistency – ensure a unified IP address for your team's access to BM or ad accounts.",
  },
  {
    culprite: "2. Ad Payment Conundrums",
    culprite_description:
      "Late payments, overspending, or utilizing a payment method linked to a previously restricted account can lead to permanent restrictions.",
  },
  {
    culprite: "3. Policy Entanglements",
    culprite_description:
      "Violating Meta Ad Policies, a critical misstep, often results in ad account restrictions due to the extensive and ever-evolving policy landscape.",
  },
  {
    culprite: "4. Broad Circles & Lackluster LPs",
    culprite_description:
      "Facebook ads restrictions may stem from poorly coordinated landing pages and an excessively wide online presence, casting a shadow on your Business Manager.",
  },
];
</script>
