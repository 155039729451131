<template>
  <section class="bg-SnowWhite min-h-screen">
    <div
      class="container px-6 py-24 flex flex-col lg:flex-row justify-between gap-14 lg:gap-48"
    >
      <div class="order-2 lg:order-1 w-full lg:w-[55%]">
        <h2
          class="font-general-sans-medium md:text-[40px] md:leading-[2.5rem] text-center lg:text-left title-gradient mb-8"
        >
          Impact on Your Business and Marketing Endeavors
        </h2>
        <div
          class="text-lg md:text-xl text-center lg:text-left flex flex-col gap-5"
        >
          <p class="md:max-w-[30rem]">
            Facebook account restrictions disrupt ad campaigns, resulting in
            missed opportunities, lost customers, and revenue setbacks. A
            restricted Meta ad account or Business Manager puts a halt to all ad
            campaigns, leading to substantial losses.
          </p>
          <p class="md:max-w-lg">
            Even if independent restoration is achieved, sales and conversions
            witness a significant downturn. In extreme cases, permanent
            restrictions necessitate the creation of new accounts, leading to
            additional setbacks.
          </p>
        </div>
      </div>

      <div class="w-full lg:w-[45%] order-1 lg:order-2">
        <img
          src="../../assets/images/why-elevate-impact.png"
          alt="lady with a laptop"
          class="w-full"
        />
      </div>
    </div>
  </section>
</template>
