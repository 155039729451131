<template>
  <section class="bg-SnowWhite">
    <div
      class="container px-6 py-16 flex flex-col lg:flex-row gap-14 md:gap-44"
    >
      <div class="w-full md:w-1/2">
        <img
          src="../../assets/images/dismisal-img.png"
          alt="lady with a laptop"
          class="w-full"
        />
      </div>

      <div class="w-full md:w-1/2">
        <h2
          class="font-general-sans-medium text-5xl md:text-6xl text-center lg:text-left title-gradient mb-8"
        >
          A Dismal Scenario for Advertisers
        </h2>
        <div
          class="max-w-[512px] mx-auto text-lg md:text-xl text-center lg:text-left flex flex-col gap-5"
        >
          <p>
            Building a business on Meta platforms involves mastering technical
            intricacies and crafting a robust online presence. Losing years of
            hard work due to a restricted Meta ad account or Business Manager is
            disheartening.
          </p>
          <p>
            How can this be rectified and prevented? Here's where
            <span class="font-general-sans-medium">Elevate Impact</span>
            steps in with a solution!
          </p>
        </div>
      </div>
    </div>
  </section>
</template>
