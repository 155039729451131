<template>
  <section class="bg-SnowWhite">
    <div class="container px-6 py-24">
      <h2 class="text-5xl md:text-6xl text-center title-gradient mb-8 md:mb-12">
        Benefits
      </h2>

      <div
        class="flex justify-center lg:justify-between flex-wrap lg:flex-nowrap gap-6 text-base md:text-2xl"
      >
        <ul
          class="space-y-2 md:space-y-3 bg-SoftPeach w-full lg:max-w-[419px] lg:min-h-[435px] py-[90px] pl-12 pr-6 rounded-[25px] border border-ThunderBird list-disc bx-shadow"
        >
          <li>Harness the power of enterprise-grade ad accounts</li>
          <li>Bid farewell to daily spending constraints</li>
          <li>Infinite unblocking capabilities</li>
          <li>Stringent quality compliance checks</li>
        </ul>

        <ul
          class="space-y-2 md:space-y-3 bg-SoftPeach w-full lg:max-w-[419px] py-[90px] pl-12 pr-6 rounded-[25px] border border-ThunderBird list-disc bx-shadow"
        >
          <li>Forge direct connections with Facebook representatives</li>
          <li>Enjoy around-the-clock support</li>
          <li>Implement domain whitelisting for enhanced security</li>
          <li>Undergo thorough ad audits</li>
        </ul>

        <ul
          class="space-y-2 md:space-y-3 bg-SoftPeach w-full lg:max-w-[419px] py-[90px] pl-12 pr-6 rounded-[25px] border border-ThunderBird list-disc bx-shadow"
        >
          <li>Explore complimentary consultations</li>
          <li>
            Partake in free consultation sessions with seasoned media buyers
          </li>
          <li>Enhance your Return on Ad Spend (ROAs)</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style scoped>
.bx-shadow {
  box-shadow: 0px 22.759px 36.078px 1.686px rgba(203, 203, 203, 0.25);
}
</style>
