<template>
  <div
    class="container mx-auto flex flex-col items-center md:flex-row md:justify-between md:py-32"
  >
    <div class="space-y-6">
      <h3 class="text-2xl md:text-4xl max-w-xl font-general-sans-medium">
        Impact on Your Business and Marketing Endeavors
      </h3>
      <p class="md:text-[20px] md:leading-[1.5rem] md:max-w-xl">
        In the dynamic landscape of Meta ad accounts, myriad restrictions, from
        basic policy hiccups to intricate challenges, can significantly impede
        businesses navigating the digital ad realm.
      </p>
      <p class="md:text-[20px] md:leading-[1.5rem] sca md:max-w-xl">
        Each restriction equals precious advertising time lost, translating into
        missed opportunities and revenue setbacks. If your Facebook account
        finds itself shackled, the first step is to uncover the root cause.
      </p>
    </div>
    <div class="flex items-center -space-x-32 md:-space-x-12 pr-10">
      <img
        src="../../assets/images/impact-img-1.svg"
        class="scale-50 md:scale-90 pb-8 md:pb-7"
        alt=""
      />
      <div class="flex items-center -space-x-36 md:-space-x-20">
        <img
          src="../../assets/images/impact-img-2.svg"
          class="scale-50 md:scale-90"
          alt=""
        />
        <img
          src="../../assets/images/impact-img-3.svg"
          class="scale-50 md:scale-90 md:relative md:-pr-4"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
