<template>
  <div
    class="border-white border-t py-6 md:py-8 pr-4 md:pr-6 transition-all duration-300"
    :class="{
      'min-h-32': expand,
      'min-h-16': !expand,
      ' border-b': idx === length - 1,
    }"
  >
    <div
      class="flex justify-between gap-5 cursor-pointer"
      @click="expand = !expand"
    >
      <h5 class="text-base md:text-4xl text-left font-general-sans-medium">
        {{ culprite }}
      </h5>
      <div>
        <img
          v-show="!expand"
          src="../../assets/svgs/plus.svg"
          class="scale-75 md:scale-90"
          alt=""
        />
        <img
          v-show="expand"
          src="../../assets/svgs/minus.svg"
          class="pr-1 pt-1 scale-110"
          alt=""
        />
      </div>
    </div>

    <div v-if="expand" class="pl-8 mt-5 text-white max-w-3xl">
      {{ culprite_description }}.
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
defineProps({
  culprite: {
    type: String,
  },
  culprite_description: {
    type: String,
  },
  idx: {
    type: Number,
  },
  length: {
    type: Number,
  },
});

const expand = ref(false);
</script>
