<template>
  <div class="bg-BlueChalk py-8 md:py-24">
    <div class="container mx-auto flex flex-col items-center justify-center">
      <h2
        class="text-center font-general-sans-medium md:text-[64px] title-gradient mb-8 md:mb-10"
      >
        Our Mission
      </h2>
      <div class="space-y-4 md:space-y-8">
        <p class="text-lg md:text-2xl text-center md:max-w-[70rem]">
          Elevate Impact is on a mission to transform performance advertising by
          empowering brands to overcome the limitations of digital advertising.
          We seek to provide companies with the tools they need to scale
          seamlessly on digital platforms by offering strategic services such as
          whitelisting, reducing ad budget limits, and assuring content
          compliance.
        </p>
        <p class="text-lg md:text-2xl text-center md:max-w-[70rem]">
          Our objective involves understanding the complexities of social media
          through direct interaction with ad platform specialists and the use of
          our significant industry experience. Elevate Impact is dedicated to
          being the catalyst for brands seeking to elevate their impact in the
          digital advertising landscape.
        </p>
      </div>
    </div>
  </div>
</template>
