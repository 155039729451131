<template>
  <section class="bg-services-hero-background bg-no-repeat bg-center bg-cover">
    <div
      class="container px-6 pt-64 pb-[60px] flex justify-between items-center gap-11 lg:gap-[54px]"
    >
      <div
        class="w-full lg:w-7/12 text-white text-center lg:text-left space-y-8 md:space-y-12"
      >
        <h3 class="text-3xl md:text-5xl font-general-sans-medium">
          Encountering roadblocks in unleashing the full potential of your
          Facebook advertising?
        </h3>
        <div class="space-y-4">
          <p
            class="font-general-sans text-xl md:text-[24px] md:leading-[1.6rem] md:max-w-xl"
          >
            Is your Business Manager hitting a wall, hindering the seamless flow
            of your operations?
          </p>
          <p class="font-general-sans text-xl md:text-2xl">
            Fret not – let
            <span class="font-general-sans-medium over"> Elevate Impact</span>
            be your guiding force!
          </p>
        </div>
      </div>

      <div class="hidden lg:block w-5/12">
        <img src="../../assets/images/iPhone-img.png" alt="Iphone" class="" />
      </div>
    </div>
  </section>
</template>
