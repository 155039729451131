<template>
  <section
    class="min-h-screen bg-hero-background bg-no-repeat bg-center bg-cover"
  >
    <div
      class="container px-6 pt-[151px] pb-20 flex justify-between items-center gap-11 lg:gap-[54px]"
    >
      <div
        class="w-full lg:w-[55%] font-general-sans-medium text-white text-center lg:text-left"
      >
        <h1
          class="max-w-2xl text-5xl sm:text-7xl md:text-[78px] md:leading-[6rem] mb-10 tracking-[-2.46px]"
        >
          Explore the vast world of advertising options!
        </h1>
        <p class="font-general-sans text-xl md:text-2xl mb-14 md:max-w-xl">
          Our dedication to guiding clients in creating an interruption-free
          advertising ecosystem is embedded in our mission. Our skilled
          advertising team specializes in the smooth scalability of your
          campaigns.
        </p>
        <div>
          <button
            @click="scrollToGetStarted"
            class="inline-block font-general-sans-medium bg-white py-4 px-8 text-black text-sm md:text-base rounded-lg"
          >
            Contact Us
          </button>
        </div>
      </div>

      <div class="hidden lg:block w-[40%]">
        <img
          src="../../assets/images/hero-image.png"
          alt="man looking at his phone in his hand"
          class="ml-auto"
        />
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "",

  setup(props, { emit }) {
    const scrollToGetStarted = () => {
      emit("scrollToGetStarted");
    };

    return {
      scrollToGetStarted,
    };
  },
};
</script>
