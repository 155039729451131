<template>
  <section class="px-2 md:px-0 mb-8 md:mb-16">
    <div
      class="bg-who-are-we-background bg-no-repeat bg-cover rounded-3xl container px-4 md:px-24 py-24 flex flex-col items-center lg:flex-row justify-between gap-14 lg:gap-24"
    >
      <div class="order-2 lg:order-1 w-full lg:w-[55%]">
        <h2
          class="font-general-sans-medium md:text-[40px] md:leading-[2.5rem] text-center lg:text-left text-white mb-8 md:mb-12"
        >
          Who Are We?
        </h2>
        <div
          class="text-lg md:text-xl text-center lg:text-left flex flex-col gap-5"
        >
          <p class="md:max-w-[32rem] text-white">
            Elevate Impact stands as a beacon among digital marketing agencies,
            specializing in Meta ad policy compliance, ad audits, unrestricted
            ad spending, and cost-effective CPM & CPA. ESP, a recognized Meta
            Partner, focuses on scaling advertising through a verified Facebook
            Business Manager and seamless Meta ad flow.  Together, we present
            the MetaPeak Performance Pack – your definitive solution to sidestep
            Facebook Ads restrictions, address Business Manager challenges,
            secure access, and attain optimal results!
          </p>
          <p class="md:max-w-[30rem] text-white">
            Facing permanent advertising access restrictions? Introducing the
            MetaPeak Performance Pack – your key to overcoming limitations,
            securing access, and achieving maximum results, uniquely tailored
            for your success. Elevate your impact and elevate your advertising
            experience with us!
          </p>
        </div>
      </div>

      <div class="w-full lg:w-[45%] order-1 lg:order-2">
        <img
          src="../../assets/images/who-are-we-img.png"
          alt="lady with a laptop"
          class="w-full"
        />
      </div>
    </div>
  </section>
</template>
