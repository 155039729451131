<template>
  <div class="py-8 md:pt-24">
    <div class="container mx-auto flex flex-col items-center justify-center">
      <h2
        class="text-center font-general-sans-medium md:text-[64px] title-gradient mb-8 md:mb-10"
      >
        Our Partners
      </h2>
      <div class="mb-10 md:mb-20">
        <p class="text-lg md:text-2xl text-center md:max-w-5xl">
          Leading Digital advertising Partners. Elevate Impact works directly
          with all Advertising platforms and supplies premier accounts for your
          needs.
        </p>
      </div>
      <div
        className="flex justify-center items-center w-full md:w-[77%] cursor-pointer"
      >
        <div
          className="flex flex-row items-center w-full sm:w-full overflow-auto scrollbar-hide space-x-3 md:space-x-8 relative py-5"
        >
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/facebook-logo.svg"
                alt="Facebook partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <!-- <div className="slides">
            <div>
              <img
                src="../../assets/logos/tiktok-logo.svg"
                alt="Tiktok partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div> -->
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/youtube-logo.svg"
                alt="Youtube partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/google-logo.svg"
                alt="Google partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/instagram-logo.png"
                alt="Instagram partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/facebook-logo.svg"
                alt="Facebook partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <!-- <div className="slides">
            <div>
              <img
                src="../../assets/logos/tiktok-logo.svg"
                alt="Tiktok partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div> -->
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/youtube-logo.svg"
                alt="Youtube partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/google-logo.svg"
                alt="Google partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/instagram-logo.png"
                alt="Instagram partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/facebook-logo.svg"
                alt="Facebook partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <!-- <div className="slides">
            <div>
              <img
                src="../../assets/logos/tiktok-logo.svg"
                alt="Tiktok partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div> -->
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/youtube-logo.svg"
                alt="Youtube partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/google-logo.svg"
                alt="Google partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/instagram-logo.png"
                alt="Instagram partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/facebook-logo.svg"
                alt="Facebook partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <!-- <div className="slides">
            <div>
              <img
                src="../../assets/logos/tiktok-logo.svg"
                alt="Tiktok partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div> -->
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/youtube-logo.svg"
                alt="Youtube partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/google-logo.svg"
                alt="Google partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
          <div className="slides">
            <div>
              <img
                src="../../assets/logos/instagram-logo.png"
                alt="Instagram partner"
                class="grayscale hover:grayscale-0 transition-all duration-200"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slides {
  animation: scroll 60s linear infinite;
}

.slides div {
  width: 10rem !important;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-1000%);
  }
}
</style>
