<template>
  <div class="container mx-auto pt-24 pb-20">
    <div
      class="flex flex-col items-center justify-center space-y-5 md:space-y-10"
    >
      <h2
        class="text-center font-general-sans-medium text-[30px] md:text-[64px] title-gradient mb-8 md:mb-10"
      >
        Contact Us
      </h2>
      <div class="flex flex-col items-center justify-center space-y-3">
        <p class="text-center md:max-w-2xl md:leading-[1.5rem] md:text-xl">
          Many clients have benefited from Elevate Impact's curation of
          impactful advertising campaigns through Facebook Business Manager
          (FBM). 
        </p>
        <p class="text-center md:max-w-xl md:leading-[1.5rem] md:text-xl">
          Together, let's elevate your eCommerce experience. Set up a
          conversation to explore how we may best meet your needs.
        </p>
      </div>
    </div>

    <form
      @submit.prevent="submitData"
      class="text-Dark text-lg md:text-xl text-left my-6 md:my-24"
    >
      <div class="flex flex-col gap-5">
        <div
          class="flex flex-col md:flex-row md:justify-between gap-x-9 gap-y-5"
        >
          <div class="w-full md:w-1/2">
            <label for="firstName" class="inline-block mb-3">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              placeholder="First Name"
              class="bg-Mercury w-full py-3 px-5 outline-none text-OsloGrey placeholder:text-OsloGrey rounded-lg"
              v-model="formData.firstName"
              required
            />
          </div>

          <div class="w-full md:w-1/2">
            <label for="lastName" class="inline-block mb-3">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              placeholder="Enter Last Name"
              class="bg-Mercury w-full py-3 px-5 outline-none text-OsloGrey placeholder:text-OsloGrey rounded-lg"
              v-model="formData.lastName"
              required
            />
          </div>
        </div>

        <div class="space-y-5">
          <div>
            <label for="email" class="inline-block mb-3">Business</label>
            <input
              type="text"
              id="business"
              name="business"
              placeholder="Business name, website, etc."
              class="bg-Mercury w-full py-3 px-5 outline-none text-OsloGrey placeholder:text-OsloGrey rounded-lg"
              v-model="formData.business"
              required
            />
          </div>
          <div>
            <label for="email" class="inline-block mb-3">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Your email"
              class="bg-Mercury w-full py-3 px-5 outline-none text-OsloGrey placeholder:text-OsloGrey rounded-lg"
              v-model="formData.email"
              required
            />
          </div>
        </div>

        <div
          class="flex flex-col md:flex-row md:justify-between gap-x-9 gap-y-5"
        >
          <div class="w-full md:w-2/5">
            <label for="country" class="inline-block mb-3">Country*</label>
            <select
              name="country"
              id="country"
              class="appearance-none bg-Mercury w-full py-3 px-5 outline-none text-OsloGrey rounded-lg cursor-pointer"
              v-model="formData.country"
              required
            >
              <option value="" disabled selected>Select Country</option>
              <option
                v-for="country in sortedCountries"
                :key="country"
                :value="country"
              >
                {{ country }}
              </option>
            </select>
          </div>

          <div class="w-full md:w-3/5">
            <label for="phoneNumber" class="inline-block mb-3"
              >Phone number*</label
            >
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone Number"
              class="bg-Mercury w-full py-3 px-5 outline-none text-OsloGrey placeholder:text-OsloGrey rounded-lg"
              v-model="formData.phoneNumber"
              required
            />
          </div>
        </div>
        <div>
          <p class="mb-4">
            I want to partner with Elevate Impact for unblocking limitless
            performance advertising on:
          </p>
          <div class="flex space-x-2 items-center">
            <input
              v-model="formData.facebook"
              type="checkbox"
              name=""
              id=""
              class="cursor-pointer border"
            />
            <p>Facebook</p>
          </div>
          <div class="flex space-x-2 items-center">
            <input
              v-model="formData.google"
              type="checkbox"
              name=""
              id=""
              class="cursor-pointer border"
            />
            <p>Google</p>
          </div>
          <div class="flex space-x-2 items-center">
            <input
              v-model="formData.bing"
              type="checkbox"
              name=""
              id=""
              class="cursor-pointer border"
            />
            <p>Bing</p>
          </div>
          <!-- <div class="flex space-x-2 items-center">
            <input
              v-model="formData.tiktok"
              type="checkbox"
              name=""
              id=""
              class="cursor-pointer border"
            />
            <p>TikTok</p>
          </div> -->
        </div>

        <div class="w-full">
          <label for="monthlyBudget" class="inline-block mb-3"
            >Advertising monthly budget
          </label>
          <select
            id="monthlyBudget"
            name="monthlyBudget"
            placeholder="Monthly Budget"
            class="appearance-none bg-Mercury w-full py-3 px-5 outline-none text-OsloGrey rounded-lg cursor-pointer"
            v-model="formData.monthlyBudget"
          >
            <option value="" disabled selected>Select Montly Budget</option>
            <option value="up_to_ten_million">Up to N10,000,000</option>
            <option value="ten_million_to_fifty_million">
              N10,000,000 - N50,000,000
            </option>
            <option value="fifty_million_and_above">&gt;N50,000,000</option>
          </select>
        </div>

        <div class="">
          <button
            class="w-full max-w-[233px] bg-Dark text-white text-sm md:text-base font-lota-semibold py-[14px] px-5 rounded-md"
            type="submit"
          >
            <span v-if="isLoading">
              <svg
                aria-hidden="true"
                role="status"
                class="inline w-4 h-4 mr-3 text-white animate-spin"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="#E5E7EB"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentColor"
                />
              </svg>
            </span>
            <span v-else>Let’s Start</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, computed } from "vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import axios from "axios";

const formData = ref({
  monthlyBudget: "",
  firstName: "",
  lastName: "",
  email: "",
  country: "",
  phoneNumber: "",
  facebook: null,
  google: null,
  bing: null,
 // tiktok: null,
});

const isLoading = ref(false);

const countries = ref([]);

const sortedCountries = computed(() => {
  return countries.value.slice().sort();
});

const airtableApiUrl =
  "https://api.airtable.com/v0/appBTTWrfB9PRFgXH/Table%201"; // Replace YourTableName
const apiKey =
  // "patFZtbCDmOjKB4Cm.2485d5205ca0f09f1f389bc38380bf9fde7de79f38097d45642c042ba8f02c98";
  "patFZtbCDmOjKB4Cm.3854837090e6b72d484c3032078c11b41cfa22b2d94911cd204dd5ec1f1584a7";

const submitData = async () => {
  isLoading.value = true;
  try {
    const records = {
      fields: {
        monthlyBudget: formData.value.monthlyBudget,
        firstName: formData.value.firstName,
        lastName: formData.value.lastName,
        email: formData.value.email,
        country: formData.value.country,
        phoneNumber: formData.value.phoneNumber,
        facebook: formData.value.facebook,
        google: formData.value.google,
        bing: formData.value.bing,
        // tiktok: formData.value.tiktok,
      },
    };

    const response = await axios.post(airtableApiUrl, records, {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        "Content-Type": "application/json",
      },
    });

    console.log("Data submitted successfully: 22", response.data);
    toast.success("Data submited successfully", {
      autoClose: 3000,
    });

    formData.value.monthlyBudget = "";
    formData.value.firstName = "";
    formData.value.lastName = "";
    formData.value.email = "";
    formData.value.country = "";
    formData.value.phoneNumber = "";
    formData.value.facebook = "";
    formData.value.google = "";
    formData.value.bing = "";
    // formData.value.tiktok = "";
  } catch (error) {
    console.error("Error posting data to Airtable:", error);
    toast.error("Error", {
      autoClose: 3000,
    });
  } finally {
    isLoading.value = false;
  }
};

onMounted(() => {
  fetch("https://restcountries.com/v3.1/all")
    .then((response) => response.json())
    .then((data) => {
      countries.value = data.map((country) => country.name.common);
    })
    .catch((error) => console.error("Error fetching countries:", error));
});
</script>
