<template>
  <section class="bg-SnowWhite min-h-screen">
    <div class="container px-6 py-24">
      <h2
        class="max-w-[714px] mx-auto title-gradient text-5xl md:text-6xl text-center mb-20"
      >
        K.E.Y Ingredients of <br />
        <span class="font-general-sans-semibold">Elevate Impact</span>
        Advertising
      </h2>

      <div
        class="flex justify-center xl:justify-between flex-wrap gap-6 text-white"
      >
        <div
          class="bg-key-ingredients-background-1 bg-no-repeat bg-center bg-cover w-full lg:w-[364px] lg:min-h-[382px] pt-[60px] px-8 pb-8 rounded-[20px] flex flex-col justify-between"
        >
          <p class="text-2xl mb-20">
            Keeping ads 100% compliant with latest Meta policies
          </p>
          <div class="font-general-sans-medium mt-auto">
            <p class="text-7xl md:text-[80px] mb-3">1000+</p>
            <p class="text-2xl md:text-[28px]">Satisfied Customers</p>
          </div>
        </div>

        <div
          class="bg-key-ingredients-background-2 bg-no-repeat bg-center bg-cover w-full lg:w-[364px] lg:min-h-[382px] pt-[60px] px-8 pb-8 rounded-[20px] flex flex-col justify-between"
        >
          <p class="text-2xl mb-20">
            Running ads from agency-level ad accounts with unlimited scaling
            potential
          </p>
          <div class="font-general-sans-medium">
            <p class="text-7xl md:text-[80px] mb-3">1000M+</p>
            <p class="text-2xl md:text-[28px]">Ad Spend</p>
          </div>
        </div>

        <div
          class="bg-key-ingredients-background-3 bg-no-repeat bg-center bg-cover w-full lg:w-[364px] lg:min-h-[382px] pt-[60px] px-8 pb-8 rounded-[20px] relative overflow-hidden"
        >
          <div class="absolute inset-0 scale-x-[100] lg:scale-x-100">
            <img
              src="../../../assets/images/key-ingredients-bg-4.png"
              alt="background image"
            />
          </div>

          <div class="relative flex flex-col justify-between h-full">
            <p class="text-2xl mb-20">
              Unblocking ad accounts, in case of emergencies
            </p>
            <div class="font-general-sans-medium">
              <p class="text-7xl md:text-[80px] mb-3">99.8%</p>
              <p class="text-2xl md:text-[28px]">Recovery Success</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
