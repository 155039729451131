<template>
  <section class="bg-white min-h-screen">
    <div class="container px-6 py-24">
      <h2 class="title-gradient text-5xl md:text-6xl text-center mb-24">
        Testimonial
      </h2>

      <div
        class="bg-SnowWhite max-w-[1109px] mx-auto py-12 md:py-14 lg:py-[71px] px-10 md:px-16 lg:px-24 rounded-[23px] bx-shadow relative"
      >
        <div class="text-black">
          <p
            class="lg:max-w-[430px] pr-1 text-xl md:text-[22px] mb-24 tracking-[-0.66px] leading-[130%]"
          >
            Have you ever wanted to scale and be capped at a 350$ daily spending
            limit? Forget about that! We will provide limitless ad accounts.
            Start scaling from day one! Have you ever wanted to scale and be
            capped at a 350$ daily spending limit? Forget about that! We will
            provide limitless ad accounts. Start scaling from day one!
          </p>
          <div>
            <p class="font-general-sans-medium text-2xl md:text-3xl mb-1">
              Simon Árpád
            </p>
            <p class="text-MediumGrey text-xs md:text-[13px]">
              Global Accountability Officer
            </p>
          </div>
        </div>

        <div class="hidden lg:block absolute top-1/2 -translate-y-1/2 right-9">
          <img
            src="../../../assets/images/lady-with-phone-tablet.png"
            alt="lady holding a phone tablet"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped>
.bx-shadow {
  box-shadow: 0px 11px 189.563px 0px rgba(21, 21, 21, 0.15);
}
</style>
