<template>
  <section class="bg-SnowWhite">
    <div
      class="container px-6 py-24 flex flex-col lg:flex-row justify-between gap-14 lg:gap-48"
    >
      <div class="order-2 lg:order-1 w-full lg:w-[55%]">
        <h2
          class="hidden md:block text-5xl md:text-6xl text-center lg:text-left title-gradient mb-8"
        >
          Why Elevate Impact?
        </h2>
        <div
          class="text-lg md:text-xl text-center lg:text-left flex flex-col gap-5"
        >
          <p>
            Say goodbye to daily spending limits!<br />Have you ever wanted to
            broaden your reach without being restricted to a $350 daily spending
            limit? That is no longer the case! Unlock limitless ad accounts with
            us and Elevate your Impact.
          </p>
          <p>
            Ensure adherence, and prosperity will follow.<br />With the help of
            our specialists, you can reduce your chances of being banned by 99%.
            How? Take advantage of our exclusive compliance service, in which we
            will guide you through the process of aligning your advertising
            assets with the specific criteria of each platform. Consider Elevate
            Impact your constant support - we've got you covered.
          </p>
        </div>
      </div>

      <div class="w-full lg:w-[45%] order-1 lg:order-2">
        <h2
          class="block md:hidden text-5xl md:text-6xl text-center lg:text-left title-gradient mb-8"
        >
          Why Elevate Impact?
        </h2>
        <div>
          <img
            src="../../assets/images/why-elevate-impact.png"
            alt="lady with a laptop"
            class="w-full"
          />
        </div>
      </div>
    </div>
  </section>
</template>
